<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="100%" />
          <hr class="my-2" />
          <b-skeleton width="30%" />
          <b-skeleton width="70%" />
          <b-skeleton width="60%" />
          <b-skeleton width="70%" />
          <b-skeleton width="40%" />
        </b-card>
      </template>
      <b-card no-body class="p-1 mb-0 order-info">
        <div v-if="cart.outletId">
          <outlet-info
            :cart="cart"
            @showOutletModal="showOutletModal"
            @changeAddress="changeAddress"
          />
          <b-row class="mt-1">
            <b-col
              cols="6"
              :class="{
                'text-danger': isSubmit && !cart.salespersonId,
              }"
            >
              {{ $t("field.salesperson") }}
              <span class="text-danger">*</span>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-link
                class="link-underline"
                :class="{
                  'text-secondary': !cart.salespersonId,
                  'text-danger': isSubmit && !cart.salespersonId,
                }"
                @click="showSalespersonModal"
                :disabled="!cart.isAllowToChangeSalesperson"
              >
                <span v-if="cart.salespersonName">
                  {{ cart.salespersonName }}
                </span>
                <span v-else>
                  {{ $t("button.set") }}
                </span>
              </b-link>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              cols="6"
              :class="{
                'text-danger': isSubmit && !cart.expectDeliveryDate,
              }"
            >
              {{ $t("field.expectDeliveryDate") }}
              <span class="text-danger">*</span>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-link
                class="link-underline"
                :class="{
                  'text-secondary': !cart.expectDeliveryDate,
                  'text-danger': isSubmit && !cart.expectDeliveryDate,
                }"
                @click="$emit('updateExpectedDeliveryDate')"
              >
                <span v-if="cart.expectDeliveryDate">
                  {{ cart.expectDeliveryDate | formatDate("LL") }}
                </span>
                <span v-else>
                  {{ $t("button.set") }}
                </span>
              </b-link>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              cols="6"
              :class="{
                'text-danger': isSubmit && !cart.orderReference,
              }"
            >
              {{ $t("field.orderReference") }}
              <span class="text-danger">*</span>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-link
                class="link-underline"
                :class="{
                  'text-secondary': !cart.orderReference,
                  'text-danger': isSubmit && !cart.orderReference,
                }"
                @click="$emit('updateOrderReference')"
                :disabled="cart.salesInvoiceId"
              >
                {{
                  cart.orderReference ? cart.orderReference : $t("button.set")
                }}
              </b-link>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              {{ $t("field.remark") }}
            </b-col>
            <b-col cols="6" class="text-right">
              <b-link
                class="link-underline"
                :class="{
                  'text-secondary': !cart.note,
                }"
                @click="$emit('updateRemark')"
              >
                {{ cart.note ? cart.note : $t("button.set") }}
              </b-link>
            </b-col>
          </b-row>
        </div>
        <b-input-group v-else>
          <b-input-group-prepend>
            <b-button size="sm" variant="primary" @click="showOutletModal">
              <feather-icon icon="UserPlusIcon" />
            </b-button>
          </b-input-group-prepend>
          <n-async-single-select
            v-model="outletId"
            ref="outlet"
            name="outlet"
            :placeholder="$t('general.pleaseChooseOutlet')"
            repository="outlet"
            selection-key="id"
            selection-label="label"
            :localization="true"
            :disabled="setOutletLoading"
            @input="onOutletChange"
          ></n-async-single-select>
        </b-input-group>

        <hr class="my-1" />

        <h4>
          <b-row align-v="center">
            <b-col>
              {{ $t("field.cart") }}
            </b-col>
            <b-col class="text-right" v-if="cartLines.length">
              <b-badge variant="light-primary">
                {{ sumTotalQuantity() }}
              </b-badge>
            </b-col>
          </b-row>
        </h4>
        <div class="cart-item-responsive" v-if="cartLines.length">
          <div v-for="(line, idx) in cartLines" :key="`line-${line.id}`">
            <cart-item-line
              :type="2"
              :index="idx"
              :item="line"
              :cart="cart"
              :isSubmit="isSubmit"
              @delete="removeItem"
              @change="onChangeQuantity"
              @addReason="addReason"
            />
            <hr v-if="idx < cartLines.length - 1" />
          </div>
        </div>
        <div class="text-center" v-else>
          <b-img
            fluid
            class="w-50 empty-cart-img"
            :src="require('@/assets/images/icons/empty-cart.svg')"
          />
          <h6>
            {{ $t("cart.cartEmpty") }}
          </h6>
          <small class="d-block">
            {{ $t("cart.startAddProduct") }}
          </small>
        </div>
        <div v-if="cart.id">
          <hr class="my-1" />
          <b-row>
            <b-col cols="6">
              <n-button-loading
                variant="outline-danger"
                class="btn-cart"
                block
                :loading="checkoutLoading"
                @click="$emit('clear')"
              >
                <span>{{ $t("button.clearCart") }}</span>
              </n-button-loading>
            </b-col>
            <b-col cols="6">
              <n-button-loading
                variant="primary"
                class="btn-cart"
                block
                :loading="checkoutLoading"
                :disabled="!cartLines.length || !cart.outletId"
                @click="$emit('proceed')"
              >
                <span>{{ $t("button.proceed") }}</span>
              </n-button-loading>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-skeleton-wrapper>

    <outlet-modal ref="outletModal" @save="onOutletChange" />
    <address-modal ref="addressModal" @save="onAddressChange" />
    <salesperson-modal ref="salespersonModal" @save="onSalespsersonChange" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BBadge,
  BInputGroup,
  BInputGroupText,
  BInputGroupPrepend,
  BButton,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonImg,
  BLink,
} from "bootstrap-vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import CartItemLine from "@/components/dp/CartItemLine.vue";
import NButtonLoading from "@/components/NButtonLoading.vue";
import OutletInfo from "@/components/dp/OutletInfo.vue";
import OutletModal from "@/components/dp/outletModal/Index.vue";
import SalespersonModal from "@/components/dp/salespersonModal/Index.vue";
import AddressModal from "@/components/dp/addressModal/Index.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BInputGroup,
    BInputGroupText,
    BInputGroupPrepend,
    BButton,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonImg,
    BLink,

    NAsyncSingleSelect,
    CartItemLine,
    NButtonLoading,
    OutletInfo,
    OutletModal,
    SalespersonModal,
    AddressModal,
  },
  props: {
    checkoutLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    setOutletLoading: {
      type: Boolean,
      default: false,
    },
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cartLines: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      outletId: null,
    };
  },
  methods: {
    sumTotalQuantity() {
      return this.cartLines.reduce(
        (a, c) => a + (c.quantity ? Number(c.quantity) : 0),
        0
      );
    },
    removeItem(item) {
      this.$emit("delete", item);
    },
    addReason(item) {
      this.$emit("addReason", item);
    },
    onChangeQuantity(item, quantity) {
      this.$emit("changeQuantity", item, quantity);
    },
    showOutletModal() {
      this.$refs.outletModal.show(this.cart);
    },
    onAddressChange(shippingOptionId) {
      this.$emit("changeAddress", shippingOptionId);
    },
    changeAddress() {
      this.$refs.addressModal.show(this.cart);
    },
    onOutletChange(outletId) {
      this.$emit("changeOutlet", outletId);
    },
    showSalespersonModal() {
      this.$refs.salespersonModal.show(this.cart);
    },
    onSalespsersonChange(salespersonId) {
      this.$emit("changeSalesperson", salespersonId);
    },
    updateExpectedDeliveryDate() {
      this.$emit("updateExpectedDeliveryDate");
    },
  },
  setup() {},
};
</script>
