<template>
  <b-modal
    :title="$t(modelTitle)"
    v-model="isShow"
    hide-footer
    centered
    no-close-on-esc
    size="xl"
  >
    <div class="order-summary">
      <b-row>
        <b-col xl="6" class="first-section">
          <n-table
            class="order-summary-table"
            ref="table"
            :fields="fields"
            :items="cartLines"
            :updatable="false"
            :deletable="false"
            :stickyHeader="true"
          >
            <template #cell(image)="data">
              <b-img
                class="cursor-pointer"
                rounded
                fluid
                :src="
                  data.item.productImage
                    ? data.item.productImage
                    : require('@/assets/images/icons/dp-deafult-icon.png')
                "
                @click="
                  previewImage(
                    data.item.productImage
                      ? data.item.productImage
                      : require('@/assets/images/icons/dp-deafult-icon.png')
                  )
                "
              />
            </template>
            <template #cell(description)="data">
              {{ trans(data.item, "productName", $i18n.locale) }}
              <small class="d-block text-primary">
                {{ data.item.productCode }}
              </small>
            </template>
            <template #cell(quantity)="data">
              {{ data.item.quantity }} {{ data.item.saleUom }}
            </template>
            <template #custom-foot>
              <b-tr>
                <b-th colspan="3" class="text-right">
                  <h6 class="mb-0">{{ $t("field.total") }}:</h6>
                </b-th>
                <b-th class="text-center">
                  <h6 class="mb-0 text-primary">
                    {{ totalQuantity }}
                  </h6>
                </b-th>
              </b-tr>
            </template>
          </n-table>
        </b-col>
        <b-col xl="6">
          <h5>
            {{ $t("field.outlet") }}
          </h5>
          <dl class="row mb-2">
            <dd class="col-6">
              {{ $t("field.outletCode") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.outletCode }}
            </dt>
            <dd class="col-6">
              {{ $t("field.outletName") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ trans(cart, "outletName", $i18n.locale) }}
            </dt>
            <dd class="col-6">
              {{ $t("field.contactNumber") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.outletContactNumber ? cart.outletContactNumber : "-" }}
            </dt>
            <dd class="col-6">
              {{ $t("field.salesperson") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.salespersonName ? cart.salespersonName : "-" }}
            </dt>
          </dl>
          <h5>
            {{ $t("field.shippingBillingInfo") }}
          </h5>
          <dl class="row">
            <dd class="col-6">
              {{ $t("field.expectDeliveryDate") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.expectDeliveryDate | formatDate("LL") }}
            </dt>
            <dd class="col-6">
              {{ $t("field.orderReference") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.orderReference }}
            </dt>
            <dd class="col-6">
              {{ $t("field.address") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ trans(cart, "outletAddress", $i18n.locale) }}
            </dt>
            <dd class="col-6">
              {{ $t("field.remark") }}
            </dd>
            <dt class="col-6 text-right text-primary">
              {{ cart.note ? cart.note : "-" }}
            </dt>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <n-button-loading
            type="button"
            variant="primary"
            :loading="loading"
            @click="save"
          >
            {{ $t("button.proceedToCheckout") }}
          </n-button-loading>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BTr, BTh, BImg } from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading.vue";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";

export default {
  components: {
    BRow,
    BCol,
    BTr,
    BTh,
    BImg,

    NButtonLoading,
    NTable,
  },
  computed: {
    totalQuantity() {
      return this.cartLines.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.quantity;
      }, 0);
    },
  },
  props: {
    modelTitle: {
      type: String,
      default: "cart.salesOrderSummmary",
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      cart: {},
      cartLines: [],
    };
  },
  methods: {
    show(cart) {
      this.cart = { ...cart };
      this.cartLines = [...cart.lines];
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    save() {
      this.loading = true;
      this.$emit("submit");
    },
  },
  setup() {
    const fields = [...TableFields];
    return {
      fields,
    };
  },
};
</script>