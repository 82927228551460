<template>
  <b-card
    :id="`product-title-${item.id}`"
    class="ecommerce-card mb-1"
    no-body
    v-if="item.id"
  >
    <b-tooltip
      :title="trans(item, 'name', $i18n.locale)"
      :target="`product-title-${item.id}`"
      triggers="hover"
      variant="light"
    >
    </b-tooltip>
    <div class="item-img text-center">
      <b-img
        :alt="item.productCode"
        fluid
        class="card-img-top product-image"
        :src="item.image"
        v-if="item.image"
      />
      <b-img
        :alt="item.productCode"
        fluid
        class="card-img-top product-image"
        :src="require('@/assets/images/icons/dp-deafult-icon.png')"
        v-else
      />
    </div>
    <b-card-body class="p-1">
      <div class="item-wrapper">
        <h6 class="item-name">
          {{ trans(item, "name", $i18n.locale) }}
        </h6>
        <div class="d-flex align-item-center justify-content-between">
          <small class="d-block text-primary"> {{ item.productCode }} </small>
          <b-badge
            :variant="item.balance > 0 ? 'light-success' : 'light-danger'"
            pill
          >
            <span v-if="item.balance > 0">
              {{ item.balance | number }} {{ item.saleUom }}
            </span>
            <span v-else>
              {{ $t("field.outOfStock") }}
            </span>
          </b-badge>
        </div>
      </div>
    </b-card-body>

    <div class="item-options text-center">
      <n-button-loading
        size="sm"
        variant="primary"
        class="btn-cart"
        block
        :loading="item.loading"
        :disabled="isDisableUpdate"
        @click="addToCart"
        v-if="!line.id"
      >
        <feather-icon
          icon="ShoppingCartIcon"
          class="mr-50"
          :class="{
            'ml-50': item.loading,
          }"
        />
        <span>{{ $t("button.addCart") }}</span>
      </n-button-loading>
      <b-row no-gutters v-else>
        <b-col class="item-option" cols="6">
          <n-button-loading
            size="sm"
            variant="danger"
            class="btn-cart"
            block
            :loading="item.loading"
            :disabled="isDisableUpdate"
            @click="removeFromCart"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
              :class="{
                'ml-50': item.loading,
              }"
            />
            <span>{{ $t("button.remove") }}</span>
          </n-button-loading>
        </b-col>
        <b-col class="item-option" cols="6">
          <n-button-loading
            size="sm"
            variant="success"
            class="btn-cart"
            block
            :loading="item.loading"
            :disabled="isDisableUpdate"
            @click="updateQuantity"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              :class="{
                'ml-50': item.loading,
              }"
            />
            <span>{{ $t("field.quantity") }}: {{ line.quantity }}</span>
          </n-button-loading>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BImg,
  BButton,
  BTooltip,
  BBadge,
  BRow,
  BCol,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
    BButton,
    BTooltip,
    BBadge,
    BRow,
    BCol,
    NButtonLoading,
  },
  computed: {
    lineIndex() {
      return this.cartLines.findIndex((element) => {
        return element.productId == this.item.id;
      });
    },
    line() {
      if (this.lineIndex != -1) {
        return this.cartLines[this.lineIndex];
      }

      return {};
    },
    isDisableUpdate() {
      if (this.cart.salesInvoiceId || this.cart.orderId) {
        return true;
      }

      return false;
    },
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cartLines: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    addToCart() {
      this.$emit("addCart", this.item, this.index);
    },
    removeFromCart() {
      this.$emit("removeCart", this.line);
    },
    updateQuantity() {
      this.$emit("updateQuantity", this.line);
    },
  },
};
</script>