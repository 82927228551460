<template>
  <b-row no-gutters align-v="center" class="cart-item pr-md-1">
    <b-col md="2" xl="1">
      <b-img
        class="cursor-pointer"
        rounded
        fluid
        :src="
          item.productImage
            ? item.productImage
            : require('@/assets/images/icons/dp-deafult-icon.png')
        "
        @click="
          previewImage(
            item.productImage
              ? item.productImage
              : require('@/assets/images/icons/dp-deafult-icon.png')
          )
        "
      />
    </b-col>
    <b-col md="10" xl="6" class="mt-1 mt-md-0">
      <div class="px-1">
        <h6 class="item-name mb-0">
          {{ trans(item, "productName", $i18n.locale) }}
        </h6>
        <small class="d-block mb-0 text-primary">
          {{ $t("field.code") }}: {{ item.productCode }}
        </small>
        <small class="d-block mb-0">
          {{ item.balance > 0 ? $t("field.inStock") : $t("field.outOfStock") }}
          <span v-if="item.balance > 0">
            :
            {{ item.balance | number }}
          </span>
        </small>
      </div>
    </b-col>
    <b-col cols="6" md="10" xl="4" class="text-center mt-50 mt-xl-0">
      <n-quantity-input
        :value="item.quantity"
        :name="`quantity-${item.id}`"
        size="sm"
        :disabled="item.loading || isDisableUpdate"
        @change="onChangeQuantity"
        @blur="onChangeQuantity"
      ></n-quantity-input>
    </b-col>
    <b-col cols="6" md="2" xl="1" class="text-right">
      <b-button
        variant="link"
        class="text-danger p-0"
        :disabled="item.loading || isDisableUpdate"
        @click="removeItem"
      >
        <b-spinner small label="Spinning" v-if="item.loading"></b-spinner>
        <feather-icon icon="Trash2Icon" size="18" v-else />
      </b-button>
    </b-col>
    <b-col cols="12" class="text-right" v-if="type == 2">
      <b-link
        class="link-underline"
        :class="{
          'text-secondary': !item.reasonCodeDescription,
          'text-danger': isSubmit && !item.reasonCodeDescription,
        }"
        @click="addReason"
      >
        {{
          item.reasonCodeDescription
            ? item.reasonCodeDescription
            : $t("field.reason")
        }}
        <span class="text-danger" v-if="!item.reasonCodeDescription">*</span>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import { BImg, BRow, BCol, BButton, BSpinner, BLink } from "bootstrap-vue";
import NQuantityInput from "@/components/NQuantityInput.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BLink,

    NQuantityInput,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisableUpdate() {
      if (this.cart.salesInvoiceId || this.cart.orderId) {
        return true;
      }

      return false;
    },
  },
  methods: {
    removeItem() {
      this.$emit("delete", this.item);
    },
    onChangeQuantity(quantity) {
      this.$emit("change", this.item, quantity);
    },
    addReason() {
      this.$emit("addReason", this.item);
    },
  },
};
</script>
